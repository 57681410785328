import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { PaymentTypeEnum } from "@application/types/PaymentType.enum";
import { SEARCH_TYPES } from "@config/const/enums";
// import { LANGUAGE } from "@config/lang";

export const Settings = {
  endpoint: "https://demo-admin.nbssoft.com",
  languages: [],
  encodeUrl: false,
  hashRouter: false,
  defaultLanguage: "en",
  links: {
    top: ["phone", "email", "b2b", "lang"],
    menu: [],
  },
  menuLinks: [],
  googleAnalyticsTrackingId: null,
  contact: {
    map: "https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed",
    mapOnFooter: false,
  },
  social: [
    {
      name: "Facebook",
      Icon: AiFillFacebook,
      url: "https://facebook.com/www.etc.mk/",
    },
    {
      name: "Instagram",
      Icon: AiFillInstagram,
      url: "https://www.instagram.com/etctravel/",
    },
  ],
  search: {
    order: [SEARCH_TYPES.charter, SEARCH_TYPES.hotel],
    charter: {
      displayDepartures: true,
      defaultAdults: 2,
      minNumAdults: 1,
      maxNumAdults: 6,
      minNumChildren: 0,
      maxNumChildren: 3,
      minBookingStartDays: 1,
      maxBookingStartDays: 365,
      minBookingDurationDays: 3,
      maxBookingDurationDays: 21,
      extraDays: 0,
    },
    hotel: {
      displayDepartures: false,
      defaultAdults: 2,
      minNumAdults: 1,
      maxNumAdults: 6,
      minNumChildren: 0,
      maxNumChildren: 3,
      minBookingStartDays: 3,
      maxBookingStartDays: 365,
      minBookingDurationDays: 1,
      maxBookingDurationDays: 30,
    },
    mkhotel: {
      displayDepartures: false,
      defaultAdults: 2,
      minNumAdults: 1,
      maxNumAdults: 6,
      minNumChildren: 0,
      maxNumChildren: 3,
      minBookingStartDays: 3,
      maxBookingStartDays: 365,
      minBookingDurationDays: 1,
      maxBookingDurationDays: 30,
    },
  },
  booking: {
    show_extend_hotel_night: false,
    passengers: {
      require_passport_no: true,
    },
    payments: [
      {
        type: PaymentTypeEnum.Cash,
        label: "payments.cash",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.CreditCard,
        label: "payments.cc",
        public: true,
        agent: false,
      },
      {
        type: PaymentTypeEnum.Invoice,
        label: "payments.inv",
        public: false,
        agent: true,
      },
    ],
  },
  iframe: {
    allowBooking: false,
  },
  b2b: {
    background: null,
    bookings: {
      show_document_download: true,
    },
  },
};
