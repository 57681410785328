import { HttpMethod } from "./const";
import { SEARCH_TYPES } from "@config/const/enums";

const deprecatedBaseUrl = (path = "") => "/v1" + path;
const baseUrl = (path = "") => "/v2" + path;
const baseUrlDep = (searchType, urlType, departureId, path = "") => {
  const segment =
    searchType === SEARCH_TYPES.charter && departureId ? `/${departureId}` : "";
  return baseUrl(`/${searchType}/${urlType}${segment}${path}`);
};

export const Requests = {
  config: () => ({
    url: deprecatedBaseUrl("/config"),
  }),
  settings: () => ({
    url: deprecatedBaseUrl("/static/setting"),
  }),
  banners: () => ({
    url: deprecatedBaseUrl("/banners"),
  }),
  offers: () => ({
    url: deprecatedBaseUrl("/offers"),
  }),
  newsletter: ({ email }) => ({
    url: deprecatedBaseUrl("/static/subscribe"),
    method: HttpMethod.POST,
    payload: { email },
  }),
  contact: ({ payload }) => ({
    url: deprecatedBaseUrl("/static/contact"),
    method: HttpMethod.POST,
    payload,
  }),
  departures: ({ searchType }) => ({
    url: baseUrl(`/${searchType}/departures`),
  }),
  destinations: ({ searchType, departureId }) => ({
    url: baseUrlDep(
      searchType === "mkhotel" ? "hotel" : searchType,
      "destinations",
      departureId
    ),
  }),
  departureDates: ({ searchType, departureId, returnType, returnId }) => ({
    url: baseUrlDep(
      searchType === "mkhotel" ? "hotel" : searchType,
      "departure-dates",
      departureId,
      `/${returnType}/${returnId}`
    ),
  }),
  returnDates: ({
    searchType,
    departureId,
    returnType,
    returnId,
    departureDate,
  }) => ({
    url: baseUrlDep(
      searchType === "mkhotel" ? "hotel" : searchType,
      "return-dates",
      departureId,
      `/${returnType}/${returnId}/${departureDate}`
    ),
  }),
  results: ({
    searchType,
    departureId,
    returnType,
    returnId,
    departureDate,
    returnDate,
    extendLastNight,
    adults,
    children,
  }) => ({
    url: baseUrlDep(
      searchType === "mkhotel" ? "hotel" : searchType,
      "results",
      departureId,
      `/${returnType}/${returnId}/${departureDate}/${returnDate}/${adults}/${children}`
    ),
    params: extendLastNight
      ? {
          extended_stay: "1",
        }
      : undefined,
  }),
  static: ({ page }) => {
    const oldPage = STATIC_MAPPING[page];
    return oldPage
      ? {
          url: deprecatedBaseUrl(`/static/page/${oldPage}`),
        }
      : null;
  },
  postBooking: ({ payload }) => ({
    url: deprecatedBaseUrl("/bookings"),
    method: HttpMethod.POST,
    payload,
  }),
  getBooking: ({ code }) => ({
    url: deprecatedBaseUrl(`/bookings/${code}`),
  }),

  // Authenticated requests
  me: () => ({
    url: deprecatedBaseUrl("/me"),
  }),
  login: ({ username, password }) => ({
    url: baseUrl("/login"),
    method: HttpMethod.POST,
    payload: {
      username,
      password,
    },
  }),
  bookings: () => ({
    url: deprecatedBaseUrl("/booking-list/1/all"),
  }),
  saveGiveAway: ({ payload }) => ({
    url: baseUrl("/give-away"),
    method: HttpMethod.POST,
    payload,
  }),
};

const STATIC_MAPPING = {
  "about-us": "About Us",
  contact: "contact",
  terms: "General travel conditions",
  privacy: "Privacy policy",
};
