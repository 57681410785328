import { createContext, useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Settings } from "../../../config/tenants/settings";

const MAPPING = {
  searchType: {
    charter: "c",
    hotel: "h",
    mkhotel: "m",
  },
  departureType: {
    airport: "a",
  },
  departureId: "number",
  returnType: {
    country: "s",
    region: "r",
    city: "c",
    hotel: "h",
    airport: "a",
  },
  returnId: "number",
  departureDate: "string",
  returnDate: "string",
  adults: "number",
  children: "array",
  hotelId: "number",
  accommodationId: "string",
};

const EMPTY_ARRAY = [];

const INITIAL_DATA = {
  options: {},
  navUrl: () => {},
};

const NavigationContext = createContext(INITIAL_DATA);

export const useNavigation = () => useContext(NavigationContext);

export function NavigationProvider({ children }) {
  const value = useProvideNavigation();
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
}

const DEFAULT_OPTIONS = {
  searchType: Settings.search.order?.[0] ?? "charter",
};

function useProvideNavigation() {
  const { data } = useParams();

  const options = useMemo(() => {
    if (!data) {
      return DEFAULT_OPTIONS;
    }
    const decoded = Settings.encodeUrl ? atob(data) : data;
    const segments = decoded.split(",");
    return Object.fromEntries(
      Object.entries(MAPPING).map(([key, val], idx) => {
        let value = segments?.[idx];
        if (val === "number") {
          value = value ? parseInt(value) || null : null;
        } else if (val === "string") {
          value = value ?? null;
        } else if (val === "array") {
          value =
            value && value !== "x"
              ? value.split(".").map((d) => Number.parseInt(d, 10))
              : EMPTY_ARRAY;
        } else {
          value = Object.entries(val).find(([, v]) => value === v)?.[0] ?? null;
        }
        return [key, value];
      })
    );
  }, [data]);

  const navUrl = useCallback((page, data) => {
    const url =
      "/" +
      page +
      "/" +
      Object.entries(MAPPING)
        .map(([key, val]) => {
          const value = data[key];
          if (val === "array") {
            return value?.length > 0 ? value.join(".") : "x";
          }
          if (val === "number") {
            return value?.toString() ?? "";
          }
          if (val === "string") {
            return value ?? "";
          }
          return Object.entries(val).find(([k]) => {
            return k === value;
          })?.[1];
        })
        .join(",");

    return Settings.encodeUrl ? btoa(url) : url;
  }, []);

  return {
    options,
    navUrl,
  };
}
